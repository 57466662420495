<template>
  <div>
    <Filters @filterChanged="onFilterChanged" />

    <b-container fluid class="mb-5">
      <b-container fluid>
        <b-row class="py-2 filter-options-container" align-v="end">
          <b-col cols="auto">
          </b-col>
          <b-col cols>
            <b-row align-v="center">
              <b-col cols="auto">
                <b-input-group class="filters-presets">
                  <template #prepend>
                    <b-dropdown
                      size="sm"
                      text="Filters Presets"
                      variant="light"
                      class=""
                      :disabled="loading"
                    >
                      <b-dropdown-item-button
                        v-for="(preset, presetIndex) in filterPresets"
                        :key="presetIndex"
                        @click="onClickedSelectFilterPreset(preset)"
                      >
                        <span>{{ preset.name }}</span>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button @click="onClickedClearFilters">Clear Filters</b-dropdown-item-button>
                    </b-dropdown>
                    <b-link class="text-lightgreen ml-2" to="/profile"><small>Configure preset filters and email alerts</small></b-link>
                  </template>

                  <b-form-input
                    type="search"
                    v-model="filter.companyName"
                    placeholder="Company Name or URL"
                    debounce="1000"
                    @update="onChangeCompanyName"
                    :disabled="loading"
                    style="min-width: 220px;"
                  ></b-form-input>
                  <b-form-input
                    type="search"
                    v-model="filter.city_or_state"
                    placeholder="City or State"
                    debounce="1000"
                    @update="onChangeCityOrState"
                    :disabled="loading"
                    class="ml-2"
                  ></b-form-input>
                  <b-form-input
                    type="search"
                    v-model="filter.country"
                    placeholder="Country"
                    debounce="1000"
                    @update="onChangeCountry"
                    :disabled="loading"
                    class="ml-2"
                    style="max-width: 140px;"
                  ></b-form-input>
                  <b-form-input
                    type="search"
                    v-model="filter.zipcodes"
                    placeholder="Zip"
                    debounce="1000"
                    @update="onChangeZipcodes"
                    class="ml-2"
                    :disabled="loading"
                    style="max-width: 100px;"
                  ></b-form-input>
                  <b-dropdown
                    variant="light"
                    class="ml-2"
                    :disabled="loading"
                    @shown="onOpenSelectIndustries"
                    @hidden="onCloseSelectIndustries"
                  >
                    <template #button-content>
                      Select Industries
                    </template>
                    <b-dropdown-form>
                      <b-form-group
                        label="Industries Presets"
                      >
                        <b-button
                          v-for="preset in industry_presets"
                          :key="preset.id"
                          @click="onClickedIndustryPreset(preset)"
                          variant="lightgreen"
                          class="mr-2"
                        >
                          {{ preset.label }}
                        </b-button>
                      </b-form-group>
                      <treeselect
                        v-model="filter.industries"
                        :multiple="true"
                        :options="industryOptions"
                        :show-count="true"
                        :always-open="true"
                        class="filter-industries"
                        style="width: 480px;"
                        placeholder="Select Industries"
                      >
                        <!-- <div slot="value-label" slot-scope="{ node }">{{ node.raw.label | truncate(8, '...') }}</div> -->
                      </treeselect>
                    </b-dropdown-form>
                  </b-dropdown>
                  <b-form-input
                    v-show="!prospecting"
                    type="search"
                    v-model="filter.page_keyword"
                    placeholder="Page keyword or URL"
                    debounce="1000"
                    @update="onChangePageKeyword"
                    class="ml-2"
                    :disabled="loading"
                    style="min-width: 220px;"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col cols="auto" class="ml-auto mode-selector-container">
                <img class="mr-2" :src="`images/mode-map-${ mode == 'map' ? 'active' : 'inactive' }.svg`" @click="toggleMode('map')" />
                <img :src="`images/mode-list-${ mode == 'list' ? 'active' : 'inactive' }.svg`" @click="toggleMode('list')" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-row>
        <b-col :class="{ 'mapModeSidebar': mode == 'map' }">
          <b-row class="my-2">
            <b-col cols="auto">
              <p class="mb-0 font-weight-bold" style="font-size: 1.7em;">{{title}}</p>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-form inline class="float-right">
                <b-form-checkbox
                  class="mr-4"
                  switch
                  v-model="includeOnlyFL"
                  @change="onChangedIncludeOnlyFL"
                  :disabled="loading || prospecting"
                  variant="danger"
                >Just Show QB/FL Contacts <span class="tooltip-helper-icon" id="tooltip-toolbarFilterIncludeOnlyFL"></span></b-form-checkbox>
                <b-tooltip target="tooltip-toolbarFilterIncludeOnlyFL">Just Show QB/FL Contacts</b-tooltip>
                <!--
                <b-form-checkbox
                  v-show="!prospecting"
                  class="mr-4"
                  switch
                  v-model="includeHidden"
                  @change="onChangedIncludeHidden"
                  :disabled="loading || prospecting"
                >Include Hidden Companies</b-form-checkbox>
                -->
                <label class="mr-sm-2">{{ formatter_number(totalRows) }} Results</label>
                <b-form-select
                  :options="pageOptions"
                  v-model="perPage"
                  @change="onChangedPerPage"
                  :disabled="loading"
                />
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="mode == 'list'">
            <b-col>
              <b-table
                ref="table-list"
                :items="loadItems"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="tableCompanies"
                show-empty
                stacked="md"
                hover
                @refreshed="onTableRefreshed"
                :busy="loading"
                thead-tr-class="text-uppercase text-nowrap small"
                :tbody-tr-class="companyRowClass"
                @row-clicked="onCompanyClicked"
              >

                <template #head(is_converted)="">
                  Converted <span class="tooltip-helper-icon" id="tooltip-tableColumnHeaderConverted"></span>
                  <b-tooltip target="tooltip-tableColumnHeaderConverted">Someone from this company completed a form, called, chatted or similar activity that shows strong intent</b-tooltip>
                </template>

                <template #cell(last_visited)="data">
                  <template v-if="!prospecting || data.value">
                    <span style="font-size: 1.2em;">{{ data.value | moment('MMMM D') }}</span>
                    <br/>
                    <span>{{ data.value | moment('h:mm A') }}</span>
                  </template>
                  <template v-else>
                  </template>
                </template>
                <template #cell(companyName)="row" class="companyfield">
                  <!--
                  <b-img-lazy
                    v-if="row.item.logo"
                    thumbnail
                    :src="row.item.logo"
                    alt=""
                    v-bind="companyLogoProps"
                  ></b-img-lazy>
                  -->
                  <strong style="font-size: 1.2em;">{{ row.item.name }}</strong>
                  <br/>
                  <span v-if="row.item.phone">{{ row.item.phone }}</span> <a v-if="row.item.website" :href="'https://' + row.item.website" target="_blank" class="text-lightgreen">{{ row.item.website }}</a>
                </template>
                <template #cell(industries)="data">
                  <b-badge
                    v-for="(industry, index) in data.value"
                    :key="index"
                    pill
                    class="industry-badge mr-1"
                    :variant="filter.industries.some(industry_code => industry_codes.find(code => code.Id == industry_code).Name == industry) && 'dark' || 'light'"
                  >{{ industry }}</b-badge>
                </template>
                <template #cell(location)="row">
                  {{ row.item.city }}, {{ row.item.state }}
                </template>
                <template #cell(info)="row">
                  <small>Revenue - {{ formatter_currency(row.item.revenue) }}</small>
                  <br/>
                  <small>Employees - {{ formatter_number(row.item.employeeCount) }}</small>
                </template>
                <template #cell(visits)="row">
                  <template v-if="!prospecting">
                    <b-icon
                      variant="lightgreen"
                      v-if="!prospecting && filter.page_keyword != '' && row.item.pages_found > 0"
                      icon="search"
                    ></b-icon>
                    <strong class="h5">{{ row.item.visits }}</strong>
                  </template>
                  <template v-else>
                  </template>
                </template>
                <template #cell(is_converted)="row">
                  <b-icon
                    v-show="row.item.is_converted"
                    icon="star-fill"
                    scale="2"
                    variant="warning"
                  ></b-icon>
                </template>
                <template #cell(is_fastlead)="row">
                  <b-icon
                    v-show="row.item.is_fastlead"
                    icon="check-circle-fill"
                    scale="2"
                    variant="success"
                  ></b-icon>
                </template>

                <template #row-details="row">
                  <b-overlay :show="companyDetails[row.item.company_id].loading">
                    <b-card-group>
                      <b-card
                        class="company-contacts-card"
                      >
                        <template #header>
                          <div class="d-flex justify-content-between align-items-center">
                            <b-form-group
                              label="Employees"
                              label-for="filter-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              :disabled="companyDetails[row.item.company_id].contacts.loading || targeted_jobtitles[row.item.company_id]"
                            >
                              <b-input-group size="sm">
                                <b-form-input
                                  id="filter-input"
                                  v-model="companyDetails[row.item.company_id].contacts.jobTitle"
                                  type="search"
                                  placeholder="Job Title"
                                  debounce="1000"
                                  @update="value => refreshCompanyContacts(row)"
                                  :disabled="companyDetails[row.item.company_id].contacts.loading || targeted_jobtitles[row.item.company_id]"
                                ></b-form-input>
                                <b-input-group-append>
                                  <b-button :disabled="companyDetails[row.item.company_id].contacts.loading || !companyDetails[row.item.company_id].contacts.jobTitle || targeted_jobtitles[row.item.company_id]" @click="companyDetails[row.item.company_id].contacts.jobTitle = '', refreshCompanyContacts(row)">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                            <b-form-checkbox
                              switch
                              v-model="targeted_jobtitles[row.item.company_id]"
                              @change="onChangedPreFilterJobTitles(row)"
                              :disabled="loading"
                              variant="danger"
                            >Display Targeted Job Titles <span class="tooltip-helper-icon" :id="'tooltip-preFilterJobTitles-' + row.item.company_id"></span></b-form-checkbox>
                            <b-tooltip :target="'tooltip-preFilterJobTitles-' + row.item.company_id">Job titles have been predefined to help improve usability. Turn this off to see all employees.</b-tooltip>
                          </div>
                        </template>
                        <b-table
                          :items="companyDetails[row.item.company_id].contacts.data"
                          :fields="contactFields"
                          show-empty
                          hover
                          small
                          :busy="companyDetails[row.item.company_id].contacts.loading"
                        >
                          <template #cell(name)="row">
                            {{ row.item.firstName }} {{ row.item.middleName }} {{ row.item.lastName }}
                          </template>
                          <template #cell(actions)="row">
                            <b-button size="sm" @click="showContact(row)">Show Contact</b-button>
                          </template>
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle mx-2"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>
                        </b-table>
                        <template v-if="companyDetails[row.item.company_id].contacts.loading">
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle mx-2"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-else>
                          <div class="company-featured-contacts-wrapper" v-if="companyDetails[row.item.company_id].contacts.featured.length">
                            <div
                              v-for="contact in companyDetails[row.item.company_id].contacts.featured"
                              :key="contact.id"
                              class="company-featured-contact-content"
                              :class="{'is_converted': contact.is_converted}"
                            >
                              <div class="company-featured-contact-basic-info">
                                <div v-if="contact.is_converted" class="customer-converted-label">
                                  <b-icon
                                    icon="star-fill"
                                    variant="warning"
                                  ></b-icon>
                                  <a class="text-warning" :href="contact.fastlead_link" target="_blank"><strong>Converted</strong></a>
                                </div>
                                <div v-else class="customer-match-label">
                                  <strong>Customer Match</strong><span class="tooltip-helper-icon" :id="'tooltip-customerMatch-' + contact.id"></span>
                                  <b-tooltip :target="'tooltip-customerMatch-' + contact.id">Contacts that are already in our customer/lead database [Quotebooks/Fastleads]</b-tooltip>
                                </div>
                                <div class="company-contact-name h5">{{ contact.firstName }} {{ contact.middleName }} {{ contact.lastName }}</div>
                                <b-badge
                                  pill
                                  class="jobTitle-badge"
                                  :variant="contact.jobTitle.toLowerCase().includes(companyDetails[row.item.company_id].contacts.jobTitle.toLowerCase() || null) && 'lightgreen' || 'light'"
                                >{{ contact.jobTitle }}</b-badge>
                              </div>
                              <div class="company-featured-contact-more-info">
                                <div>{{ contact.phone }} {{ contact.email }}</div>
                                <div>{{ contact.street }}, {{ contact.city }}, {{ contact.state }} {{ contact.zipCode }}, {{ contact.country }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="company-contacts-wrapper">
                            <div
                              v-for="contact in companyDetails[row.item.company_id].contacts.data"
                              :key="contact.id"
                              class="company-contact-content mr-3 mb-2"
                            >
                              <div><b-button variant="link" size="sm" @click="showContact({item: {id: contact.id}})"><div class="company-contact-name h5">{{ contact.firstName }} {{ contact.middleName }} {{ contact.lastName }}</div></b-button></div>
                              <b-badge
                                pill
                                class="jobTitle-badge"
                                :variant="(targeted_jobtitles[row.item.company_id] ? predefinedJobTitles.some(keyword => contact.jobTitle.toLowerCase().includes(keyword.toLowerCase() || null)) : contact.jobTitle.toLowerCase().includes(companyDetails[row.item.company_id].contacts.jobTitle.toLowerCase() || null)) && 'lightgreen' || 'light'"
                              >{{ contact.jobTitle }}</b-badge>
                            </div>
                          </div>
                        </template>
                        <template #footer>
                          <b-pagination
                            v-model="companyDetails[row.item.company_id].contacts.page"
                            :total-rows="companyDetails[row.item.company_id].contacts.total"
                            :per-page="companyDetails[row.item.company_id].contacts.per_page"
                            align="center"
                            class="my-0"
                            limit="10"
                            @change="page => loadCompanyContacts(row.item, page)"
                            :disabled="companyDetails[row.item.company_id].contacts.loading"
                          />
                        </template>
                      </b-card>
                      <b-card v-if="!prospecting">
                        <template #header>
                          <span>Visits ({{ companyDetails[row.item.company_id].logs.data.length }})</span>
                          <span
                            v-if="companyDetails[row.item.company_id].totalBrandfolderCount"
                            class="mx-2"
                          >
                            BrandFolder Clicks ({{ companyDetails[row.item.company_id].totalBrandfolderCount }})
                            <b-icon variant="lightgreen" icon="search" @click="showBrandFolderClicks(companyDetails[row.item.company_id])"></b-icon>
                          </span>
                          <b-button
                            :variant="'link'/*companyDetails[row.item.company_id].is_alert_disabled ? 'success' : 'danger'*/"
                            @click="toggleAlert(row)"
                            size="sm"
                          >
                            {{ companyDetails[row.item.company_id].is_alert_disabled ? 'Resume Alerts' : 'Stop Alerts' }}
                          </b-button>
                        </template>
                        <div
                          v-for="date in companyDetails[row.item.company_id].logs.dates"
                          :key="date"
                          class="logs-by-date"
                        >
                          <div class="d-flex justify-content-between align-items-center">
                            <strong>{{ date | moment('MMMM D') }}</strong>
                            <small class="text-muted">
                              Duration: {{ moment(companyDetails[row.item.company_id].logs.groups[date].logs[0].at).from(moment(companyDetails[row.item.company_id].logs.groups[date].logs[companyDetails[row.item.company_id].logs.groups[date].logs.length - 1].at), true) }}
                            </small>
                          </div>
                          <div class="logs-list">
                            <div
                              v-for="(log, index) in companyDetails[row.item.company_id].logs.groups[date].logs"
                              :key="log.id"
                              class="log-entry"
                            >
                              <span>{{ index + 1 }}.</span>
                              <a :href="log.url" target="_blank" v-html="highlight(log.title || log.url, filter.page_keyword)"></a>
                              <b-badge
                                v-if="log.meta && log.meta.brandfolder && log.meta.brandfolder.installed && log.meta.brandfolder.clicks.length > 0"
                                variant="lightgreen"
                                class="mr-2"
                              >
                                <span>BC: {{ log.meta.brandfolder.clicks.length }}</span>
                              </b-badge>
                              <span class="log-duration" :class="getDurationLabelBackground(log.enter_at, log.exit_at)">{{ getDurationLabel(log.enter_at, log.exit_at) }}</span>
                            </div>
                          </div>
                        </div>
                      </b-card>
                    </b-card-group>
                  </b-overlay>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mx-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="mode == 'map'">
            <b-col style="max-height: calc(100vh - 350px); overflow: auto;">
              <b-table
                ref="table-map"
                :items="loadItems"
                :fields="fields_for_map"
                :per-page="perPage"
                :current-page="currentPage"
                class="tableCompanies"
                thead-class="d-none"
                show-empty
                stacked="md"
                hover
                @refreshed="onTableRefreshed"
                :busy="loading"
                @row-clicked="onCompanyClickedFromMap"
              >
                <template #cell(info)="row">
                  <b-img-lazy
                    v-if="row.item.logo"
                    thumbnail
                    :src="row.item.logo"
                    alt=""
                    v-bind="companyLogoProps"
                  ></b-img-lazy>
                  <strong style="font-size: 1.2em;">{{ row.item.name }}</strong>
                  <div>
                    {{ row.item. street }}, {{ row.item.city }}, {{ row.item.state }}
                  </div>
                  <div>
                    <span v-if="row.item.phone">{{ row.item.phone }}</span> <a v-if="row.item.website" :href="'https://' + row.item.website" target="_blank" class="text-lightgreen">{{ row.item.website }}</a>
                  </div>
                  <div>
                    <b-badge
                      v-for="(industry, index) in formatter_industries(row.item.industries)"
                      :key="index"
                      pill
                      class="industry-badge mr-1"
                      :variant="filter.industries.some(industry_code => industry_codes.find(code => code.Id == industry_code).Name == industry) && 'dark' || 'light'"
                    >{{ industry }}</b-badge>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mx-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                class="my-0"
                limit="10"
                @change="onChangedCurrentPage"
                :disabled="loading"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="mode == 'map'">
          <GmapMap
            :center="mapCenter"
            :zoom="mapZoomLevel"
            style="width:100%;  height: 100%; min-height: 600px;"
          >
            <template
              v-for="(company, index) in companies"
            >
              <GmapMarker
                v-if="company.position"
                :key="index"
                :position="company.position"
                @click="onCompanyClickedFromMap({position: company.position})"
              />
            </template>
          </GmapMap>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="contactModal"
      hide-footer
      no-close-on-backdrop
      title="Contact Details"
      size="xl"
    >
      <b-overlay :show="contactDetails.loading" style="min-height: 150px;">
        <b-card v-if="contactDetails.loaded">
          <b-row class="mb-4">
            <b-col>
              <img v-if="contactDetails.picture" :src="contactDetails.picture" alt="" style="max-width: 72px;" class="mr-2 rounded float-left" />
              <div class="float-left">
                <h3>{{ contactDetails.firstName }} {{ contactDetails.middleName }} {{ contactDetails.lastName }}</h3>
                <small>{{ contactDetails.jobTitle }}</small>
              </div>
              <div
                class="float-left ml-4"
              >
                <b-overlay :show="contactDetails.sending" spinner-small>
                  <b-button
                    v-if="!contactDetails.is_fastlead"
                    @click="sendContactToFastLeads(contactDetails)"
                  >Add to FastLeads</b-button>
                  <p v-else class="text-success">Added to FastLeads</p>
                  <div
                    v-if="contactDetails.fastleads"
                  >
                    <p
                      v-if="!contactDetails.fastleads.success"
                      class="text-danger"
                    >{{ contactDetails.fastleads.error.message }}</p>
                  </div>
                </b-overlay>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h4>Contact details</h4>
              <p>{{ contactDetails.phone }}</p>
              <p>{{ contactDetails.email }}</p>
            </b-col>
            <b-col>
              <h4>Location</h4>
              <p>{{ contactDetails.street }}, {{ contactDetails.city }}, {{ contactDetails.state }} {{ contactDetails.zipCode }}, {{ contactDetails.country }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-modal
      id="brandFolderClicksModal"
      hide-footer
      title="BrandFolder Clicks"
      size="xl"
    >
      <div
        v-for="(log, logIndex) in brandFolderClicks"
        :key="logIndex"
      >
        <span class="text-muted">{{ new Date(log.at).toLocaleTimeString() }}</span><span class="ml-4">{{ log.link }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filters from '@/components/Filters.vue'

export default {
  name: 'Companies',
  components: {
    Filters,
  },
  data() {
    return {
      mode: 'list',
      mapCenter: { lat: 0, lng: 0 },
      mapZoomLevel: 5,
      currentPage: 1,
      perPage: 10,
      sortBy: 'last_visited',
      sortDesc: true,
      includeOnlyFL: false,
      includeHidden: false,
      filterName: '',
      defaultFilter: {
        companyName: '',
        country: '',
        city_or_state: '',
        industries: [],
        page_keyword: '',
        zipcodes: '',
      },
      filter: {
        companyName: '',
        country: '',
        city_or_state: '',
        industries: [],
        page_keyword: '',
        zipcodes: '',
      },
      tempFilterIndustries: [],
      pageOptions: [
        10,
        20,
        30
      ],
      detailsLoading: {},
      companyDetails: {},
      contactFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'jobTitle',
          label: 'Job Title',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      companyLogoProps: {
        width: 48,
        height: 48,
        class: 'mr-2',
        onerror: 'this.style.display = \'none\';',
        style: 'max-height: 48px;',
      },
      contactDetails: {
        loading: false,
      },
      previousRequest: null,
      targeted_jobtitles: {},
      brandFolderClicks: [],
    }
  },
  computed: {
    ...mapGetters('companies', {
      companies: 'data',
      loading: 'loading',
      page: 'page',
      size: 'size',
      totalRows: 'total',
    }),
    ...mapGetters('filters', {
      selectedSiteIDs: 'selectedSiteIDs',
      prospecting: 'prospecting',
    }),
    ...mapGetters('auth', {
      filterPresets: 'userFilters',
    }),
    ...mapGetters('settings/industries', {
      industry_codes: 'industry_codes',
      industry_presets: 'industry_presets',
    }),
    ...mapGetters('settings/jobtitles', {
      predefinedJobTitles: 'data',
    }),
    industryOptions: function() {
      const options = []
      this.industry_codes.forEach(el => {
        const node = {
          id: el.Id,
          label: el.Name,
        }
        const parts = el.Id.split('.')
        if (parts.length == 1) {
          options.push(node)
        } else {
          const parentOption = options.find(option => option.id == parts[0])
          if (parentOption) {
            if (!parentOption.children) parentOption.children = []
            parentOption.children.push(node)
          }
        }
      })
      return options
    },
    title: function() {
      return this.prospecting ? 'Prospecting' : 'Website Activity'
    },
    fields: function() {
      return [
        {
          key: 'last_visited',
          label: 'Activity',
          sortable: !this.prospecting,
          class: 'text-nowrap small align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'companyName',
          label: 'Company',
          class: 'text-nowrap align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'industries',
          label: 'Industry',
          formatter: 'formatter_industries',
          class: 'align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'location', // virtual
          label: 'Location',
          class: 'align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'info',
          label: 'Info',
          class: 'text-nowrap align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'visits',
          label: 'Visits',
          sortable: !this.prospecting,
          formatter: 'formatter_number',
          class: 'text-center align-middle',
          thClass: 'text-nowrap small',
        },
        {
          key: 'is_converted',
          thClass: 'text-nowrap small',
          class: 'text-center align-middle',
        },
        {
          key: 'is_fastlead',
          label: 'IN QB/FL',
          thClass: 'text-nowrap small',
          class: 'text-center align-middle',
        },
        // {
        //   key: 'actions', // virtual
        //   label: '',
        //   thClass: 'text-nowrap small',
        // },
      ]
    },
    fields_for_map: function() {
      return [
        {
          key: 'info',
          label: 'Details',
        },
      ]
    }
  },
  watch: {
    page: function (val) {
      this.currentPage = val
    },
    // size: function (val) {
    //   this.perPage = val
    // },
  },
  mounted: function() {
    this.$store.commit('companies/setPage', this.currentPage)
    this.$store.commit('companies/setSize', this.perPage)

    try {
      this.targeted_jobtitles = JSON.parse(localStorage.getItem('targeted_jobtitles') || '{}')
    } catch(e) {
      this.targeted_jobtitles = {}
    }

    // this.geolocate();
  },
  filters: {
    datetime: function(value) {
      if (!value) return ''
      return new Date(value).toLocaleString()
    },
    truncate: function(text, length, clamp) {
      clamp = clamp || '...'
      var node = document.createElement('div')
      node.innerHTML = text
      var content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },
    highlight: function(text, searchTerm) {
      return this.highlight(text, searchTerm)
    }
  },
  methods: {
    toggleMode(m) {
      if (this.mode != m) {
        this.mode = m
      }
    },
    onFilterChanged() {
      this.$store.commit('companies/resetPage')
      if (this.mode == 'map') {
        this.$refs['table-map'].refresh()
      } else {
        this.$refs['table-list'].refresh()
      }
    },
    onChangedIncludeHidden() {
      this.onFilterChanged()
    },
    onChangedIncludeOnlyFL() {
      this.onFilterChanged()
    },
    onChangedPerPage(value) {
      this.$store.commit('companies/setSize', value)
      this.$store.commit('companies/resetPage')
    },
    onChangedCurrentPage(page) {
      this.$store.commit('companies/setPage', page)
    },
    onTableRefreshed() {
      this.detailsLoading = {}
    },
    onCompanyClicked(item) {
      item._showDetails = !item._showDetails
      if (!this.companyDetails[item.company_id].loaded) {
        this.companyDetails[item.company_id].loading = true
        const promises = [
          this.loadCompanyContacts(item),
        ]
        if (!this.prospecting) {
          promises.push(
            this.$store.dispatch('companies/loadCompanyDetails', {
              company_id: this.companyDetails[item.company_id].company_id,
              page_keyword: this.filter.page_keyword,
            })
            .then(data => {
              this.companyDetails[item.company_id].logs.data = data.logs
              // const dates = [...new Set(data.logs.map(log => new Date(log.enter_at)).map(enter_at => enter_at.getDateString()))]
              let totalBrandfolderCount = 0;
              const groups = {}
              data.logs.forEach(log => {
                const date = Date.getDateString(new Date(log.enter_at))
                if (!groups[date]) {
                  groups[date] = {
                    logs: [],
                    duration: 0,
                  }
                }
                groups[date].logs.push(log)
                if (log.meta && log.meta.brandfolder && log.meta.brandfolder.installed) {
                  totalBrandfolderCount += log.meta.brandfolder.clicks.length
                }
              })
              this.companyDetails[item.company_id].totalBrandfolderCount = totalBrandfolderCount;
              this.companyDetails[item.company_id].logs.groups = groups
              this.companyDetails[item.company_id].logs.dates = Object.keys(groups)
            }),
          )
        }
        Promise.all(promises).finally(() => {
          this.companyDetails[item.company_id].loading = false
          this.companyDetails[item.company_id].loaded = true
        })
      }
    },
    onCompanyClickedFromMap(item) {
      if (item.position) {
        this.mapCenter = item.position
        this.mapZoomLevel = 12
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.mapCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    loadItems() {
      return this.$store.dispatch('companies/load', {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        includeHidden: this.includeHidden,
        includeOnlyFL: this.includeOnlyFL,
        companyName: this.filter.companyName,
        country: this.filter.country,
        city_or_state: this.filter.city_or_state,
        industries: this.filter.industries,
        page_keyword: this.filter.page_keyword,
        zipcodes: this.filter.zipcodes,
        before(request) {

          // abort previous request, if exists
          if (this.previousRequest) {
            this.previousRequest.abort();
          }

          // set previous request on Vue instance
          this.previousRequest = request;
        }
      })
      .then(companies => {
        this.companyDetails = Object.assign({}, ...companies.map(company => ({
          [company.id]: {
            company_id: company.id,
            is_alert_disabled: company.is_alert_disabled,
            loaded: false,
            loading: false,
            logs: {
              data: [],
            },
            contacts: {
              loading: false,
              total: 0,
              page: 1,
              per_page: 6,
              jobTitle: '',
              data: [],
              featured: [],
              preFilterJobTitles: true,
            },
          }
        })))
        const company_with_position = companies.find(company => company.position)
        if (company_with_position) {
          this.mapCenter = company_with_position.position
        }
        this.mapZoomLevel = 5
        return companies.map(company => ({
          ...company,
          _showDetails: false
        }))
      })
    },
    companyRowClass(item, type) {
      if (!item || type !== 'row') return
      if (item._showDetails) return 'table-secondary'
    },
    formatter_industries(value) {
      try {
        return JSON.parse(value || '[]')
      } catch(e) {
        return []
      }
    },
    formatter_currency(value) {
      try {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value)
      } catch(e) {
        return '$0'
      }
    },
    formatter_number(value) {
      try {
        return new Intl.NumberFormat().format(value)
      } catch(e) {
        return 0
      }
    },
    loadCompanyContacts(item, page = 1) {
      this.companyDetails[item.company_id].contacts.loading = true
      return this.$store.dispatch('companies/loadCompanyContacts', {
        company_id: this.companyDetails[item.company_id].company_id,
        per_page: this.companyDetails[item.company_id].contacts.per_page,
        page,
        jobTitle: this.companyDetails[item.company_id].contacts.jobTitle,
        targtedOnly: this.targeted_jobtitles[item.company_id],
      })
      .then(data => {
        this.companyDetails[item.company_id].contacts.data = data.data
        this.companyDetails[item.company_id].contacts.featured = data.featured || []
        this.companyDetails[item.company_id].contacts.total = data.maxResults
        this.companyDetails[item.company_id].contacts.loading = false
      })
    },
    refreshCompanyContacts(row) {
      this.companyDetails[row.item.company_id].contacts.page = 1
      this.loadCompanyContacts(row.item)
    },
    onChangedPreFilterJobTitles(row) {
      localStorage.setItem('targeted_jobtitles', JSON.stringify(this.targeted_jobtitles))
      this.refreshCompanyContacts(row)
    },
    showContact(row) {
      this.contactDetails = Object.assign({}, {
        loading: true,
        loaded: false,
      })
      this.$bvModal.show('contactModal')
      this.$store.dispatch('companies/loadContactDetails', {
        person_id: row.item.id,
      })
      .then(data => {
        Object.assign(this.contactDetails, data, {
          loading: false,
          loaded: true,
        })
      })
    },
    sendContactToFastLeads(contact) {
      this.contactDetails = Object.assign({}, this.contactDetails, {
        sending: true,
        fastleads: undefined,
      })
      this.$store.dispatch('companies/sendContactToFastLeads', {
        person_id: contact.id,
      })
      .then(data => {
        Object.assign(this.contactDetails, data.contact, {
          sending: false,
          fastleads: data.fastleads,
        })
      })
    },
    toggleAlert(row) {
      if (!confirm(`Do you really want to ${this.companyDetails[row.item.company_id].is_alert_disabled ? 'resume' : 'stop'} alerts from this company?`)) return
      this.companyDetails[row.item.company_id].loading = true
      this.$store.dispatch('companies/setAlertStatus', {
        company_id: row.item.company_id,
        status: !this.companyDetails[row.item.company_id].is_alert_disabled,
      })
      .then(() => {
        this.companyDetails[row.item.company_id].is_alert_disabled = !this.companyDetails[row.item.company_id].is_alert_disabled
      })
      .finally(() => {
        this.companyDetails[row.item.company_id].loading = false
      })
    },
    onClickedClearFilters() {
      this.filter = Object.assign({}, this.defaultFilter)
      this.onFilterChanged()
    },
    onClickedSelectFilterPreset(preset) {
      this.filter = Object.assign({}, this.defaultFilter)
      preset.data.forEach(cond => {
        this.filter[cond.key] = cond.value
      })
      this.onFilterChanged()
    },
    onClickedIndustryPreset(preset) {
      this.filter.industries = preset.industries.slice()
    },
    onOpenSelectIndustries() {
      this.tempFilterIndustries = this.filter.industries.slice()
    },
    onCloseSelectIndustries() {
      if (!Array.equalsIgnoreOrder(this.tempFilterIndustries, this.filter.industries)) {
        this.onFilterChanged()
      }
    },
    onChangeCompanyName() {
      this.onFilterChanged()
    },
    onChangeCountry() {
      this.onFilterChanged()
    },
    onChangeCityOrState() {
      this.onFilterChanged()
    },
    onChangeZipcodes() {
      this.onFilterChanged()
    },
    onChangePageKeyword() {
      this.onFilterChanged()
    },
    highlight(text, searchTerm) {
      if (searchTerm == '') return text
      const searchTerms = searchTerm.split(/\s*(?:;|,|:|$)\s*/).filter(v => v !== '')
      if (searchTerms.length == 0) return text
      var iQuery = new RegExp(searchTerms.join('|'), 'ig')
      return text.toString().replace(iQuery, (matched) => '<span class="highlighted">' + matched + '</span>')
    },
    getDurationLabel(enter_at, exit_at) {
      const seconds = this.moment(exit_at).diff(this.moment(enter_at), 'seconds')
      if (seconds < 50) {
        return seconds + 's'
      } else if (seconds < 70) {
        return '1m'
      } else if (seconds < 120) {
        return '1m+'
      } else {
        return '2m+'
      }
    },
    getDurationLabelBackground(enter_at, exit_at) {
      const seconds = this.moment(exit_at).diff(this.moment(enter_at), 'seconds')
      if (seconds < 50) {
        return 'bg-level-0'
      } else if (seconds < 70) {
        return 'bg-level-1'
      } else if (seconds < 120) {
        return 'bg-level-2'
      } else {
        return 'bg-level-3'
      }
    },
    showBrandFolderClicks(companyDetails) {
      this.brandFolderClicks = companyDetails.logs.data.filter(log => log.meta && log.meta.brandfolder && log.meta.brandfolder.clicks.length > 0).map(log => log.meta.brandfolder.clicks).flat().sort((a, b) => new Date(b.at) - new Date(a.at))
      this.$bvModal.show('brandFolderClicksModal')
    },
  },
}
</script>